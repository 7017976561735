import { useTranslation } from 'react-i18next'
import { useTheme } from '../../lib/hooks'

import useCheckout from '../Checkout/hooks/useCheckout'
import { SummaryDetail, SummaryTable, SummaryTotal, SummaryWrapper } from './Summary.styles'
import SummaryItem from './SummaryItem'

const Summary = () => {
  const { cart, cartTotal } = useCheckout()
  const themes = useTheme()
  const { t } = useTranslation()

  return (
    <SummaryWrapper>
      <SummaryTable>
        {cart.map(product => (
          <SummaryItem key={product.id} product={product} />
        ))}
      </SummaryTable>
      <SummaryDetail>
        <dt>{t('subtotal')}</dt>
        {themes.currency === '£' ? <dd>
          {themes.currency}
          {(cartTotal / 1.23).toFixed(2)}
        </dd> : <dd>
          {(cartTotal / 1.23).toFixed(2)}
          {themes.currency}
        </dd>}
      </SummaryDetail>
      <SummaryDetail>
        <dt>{t('tax')}</dt>
        {themes.currency === '£' ? <dd>
          {themes.currency}
          {(cartTotal - cartTotal / 1.23).toFixed(2)}
        </dd> : <dd>
          {(cartTotal - cartTotal / 1.23).toFixed(2)}
          {themes.currency}
        </dd>}

      </SummaryDetail>
      <SummaryTotal>
        <dt>{t('total')}</dt>
        {themes.currency === '£' ? <dd>
          {themes.currency}
          {cartTotal.toFixed(2)}
        </dd> : <dd>
          {cartTotal.toFixed(2)}
          {themes.currency}
        </dd>}

      </SummaryTotal>
    </SummaryWrapper>
  )
}

export default Summary
