import mrowkaProducts from '@/data/mrowka/products.json'
import travisProducts from '@/data/travisPerkins/products.json'

import { baseTheme } from './base'

export const themes = {
  travis: {
    ...baseTheme,
    language: "en",
    currency: '£',
    colors: {
      ...baseTheme.colors,
      global: '#00573C',
      primary: '#FBAD1D',
      primaryDark: '#004630',
    },
    font: 'Poppins',
    title: 'Travis Perkins - BergRetail',
    assets: {
      logo: require('@/assets/img/logo-full.svg').default,
      miniLogo: require('@/assets/img/logo.svg').default,
    },
    products: travisProducts || []
  },
  mrowka: {
    ...baseTheme,
    language: "pl",
    currency: 'zł',
    colors: {
      ...baseTheme.colors,
      global: '#0c3b6e',
      primary: '#ffbf00',
      primaryDark: '#FF8700',
    },
    font: 'Ubuntu',
    title: 'Mrówka - BergRetail',
    assets: {
      logo: require('@/assets/img/logo_mrowka.jpg'),
      miniLogo: require('@/assets/img/logo_mrowka.png'),
    },
    products: mrowkaProducts || []
  },
}
