import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import useLayout from '@/layout/useLayout'
import { useTheme } from '../../../../lib/hooks'

import useCheckout from '../../hooks/useCheckout'
import Step from '../Step/Step'
import { PaymentOptions, PaymentTotal, PaymentVendor } from './Payment.styles'
import PaymentOption from './PaymentOption'

const Payment = () => {
  const { t } = useTranslation()
  const { cartTotal, paymentMethod, setPaymentMethod, setCurrentStep, setPaymentError, setIsPaymentLoading } = useCheckout()
  const { setShowBack } = useLayout()
  const themes = useTheme()
  let paymentProvider
  try {
    paymentProvider = window.BtDevice?.getPaymentProviderLogo();
  } catch (error) {
    console.error("Error getting payment provider logo:", error);
  }
  paymentProvider = paymentProvider || process.env.REACT_APP_PAYMENT_PROVIDER || undefined

  const methods = [
    {
      icon: 'card',
      name: 'CARD',
      title: t('creditCard')
    },
    {
      icon: 'cash',
      name: 'CASH',
      title: t('cash')
    }
  ]

  useEffect(() => {
    if (window.BtApp) {
      window.BtApp.paymentFinished = (code, msg) => {
        console.log('[APP] paymentFinished', code, msg)
        if (Number(code) !== 0) {
          setPaymentError(msg)
        }
        setCurrentStep('CONFIRMATION')
        setIsPaymentLoading(false)
      }
    }

    setShowBack(true)

    return () => {
      setShowBack(false)
      setIsPaymentLoading(false)
    }
  }, [])

  return (
    <Step>
      <PaymentTotal>
        <dl>
          <dt>{t('totalToPay')}</dt>
          {themes.currency === '£' ? <dd>
            {themes.currency}
            {cartTotal.toFixed(2)}
          </dd> : <dd>
            {cartTotal.toFixed(2)}
            {themes.currency}
          </dd>}

        </dl>
      </PaymentTotal>
      <PaymentOptions>
        {methods.map(({ icon, name, title }) => (
          <PaymentOption key={name} onClick={() => setPaymentMethod(name)} icon={icon} active={paymentMethod === name}>
            {title}
          </PaymentOption>
        ))}
      </PaymentOptions>
      {paymentProvider && (
        <PaymentVendor>
          <span>Powered by</span>
          <img src={`/img/payments/${paymentProvider}.svg`} alt="" />
        </PaymentVendor>
      )}
    </Step>
  )
}

export default Payment
