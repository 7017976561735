import { useNavigate } from 'react-router'
import { useTheme } from '../../lib/hooks'


const { ProductItemWrapper, ProductItemImage, ProductItemContent, ProductItemTitle, ProductItemPrice } = require('./ProductItem.styles')

const ProductItem = ({ title, imageUrl, price, id }) => {
  const navigate = useNavigate()
  const themes = useTheme()
  return (
    <ProductItemWrapper onClick={() => navigate(`/product/${id}`)}>
      <ProductItemImage>
        <img src={imageUrl} alt={title} />
      </ProductItemImage>
      <ProductItemContent>
        <ProductItemTitle>{title}</ProductItemTitle>
        {themes.currency === '£' ? <ProductItemPrice>
          {themes.currency}
          {price.toFixed(2)}
        </ProductItemPrice> : <ProductItemPrice>
          {price.toFixed(2)}
          {themes.currency}
        </ProductItemPrice>}

      </ProductItemContent>
    </ProductItemWrapper>
  )
}

export default ProductItem
