import { AnimatePresence } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import Button from '@/components/Button'
import View from '@/layout/View'

import Summary from '../Summary'
import Cart from './components/Cart'
import Confirmation from './components/Confirmation'
import Payment from './components/Payment'
import useCheckout from './hooks/useCheckout'

const CheckoutFooter = () => {
  const { currentStep, setCurrentStep, paymentMethod, cartTotal, reset, isPaymentLoading, setIsPaymentLoading } = useCheckout()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const resetCheckout = () => {
    reset()
    navigate('/')
  }

  const handlePayment = () => {
    setIsPaymentLoading(true);

    if (paymentMethod === 'CARD') {
      try {
        if (window.BtDevice && typeof window.BtDevice.startPayment === 'function') {
          window.BtDevice.startPayment(cartTotal);
        } else {
          console.error('[DEVICE] startPayment not available');
          setTimeout(() => {
            if (window.BtApp && typeof window.BtApp.paymentFinished === 'function') {
              window.BtApp.paymentFinished(0, 'Payment simulated in development');
            } else {
              setCurrentStep('CONFIRMATION');
              setIsPaymentLoading(false);
            }
          }, 2000);
        }
      } catch (error) {
        console.error('[DEVICE] startPayment error', error);
        setIsPaymentLoading(false);
      }
    } else {
      setCurrentStep('CONFIRMATION');
      setIsPaymentLoading(false);
    }
  }

  if (currentStep === 'CART') {
    return (
      <Button variant="primary" onClick={() => setCurrentStep('PAYMENT')} appendIcon="angle-right">
        {t('goNext')}
      </Button>
    )
  } else if (currentStep === 'PAYMENT') {
    return (
      <Button variant="primary" onClick={() => handlePayment()} appendIcon="angle-right" isLoading={isPaymentLoading}>
        {t('payNow')}
      </Button>
    )
  } else if (currentStep === 'CONFIRMATION') {
    return (
      <Button block={true} onClick={() => resetCheckout()}>
        {t('close')}
      </Button>
    )
  }
}

const Checkout = () => {
  const { currentStep, steps } = useCheckout()
  const { t } = useTranslation()

  return (
    <View
      steps={steps}
      currentStep={currentStep}
      showClose
      showBack
      collapsibleComponent={currentStep === 'PAYMENT' && <Summary />}
      collapsibleTitle={t('orderSummary')}
      footer={<CheckoutFooter />}
    >
      <AnimatePresence mode="wait">
        {currentStep === 'CART' && <Cart key="CART" />}
        {currentStep === 'PAYMENT' && <Payment key="PAYMENT" />}
        {currentStep === 'CONFIRMATION' && <Confirmation key="CONFIRMATION" />}
      </AnimatePresence>
    </View>
  )
}

export default Checkout
