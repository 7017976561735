import { useEffect } from 'react';
import { useTheme } from './hooks';
import i18n from './i18n';

export const I18nProvider = ({ children }) => {
  const themes = useTheme();

  useEffect(() => {
    if (themes.language) {
      i18n.changeLanguage(themes.language);
    }
  }, [themes.language]);

  return children;
};
