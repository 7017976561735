import { AnimatePresence } from 'framer-motion'
import { useEffect } from 'react'
import { Route, Routes, useLocation } from 'react-router'
import styled from 'styled-components'
import { ThemeProvider } from 'styled-components'
import { I18nProvider } from './lib/I18nProvider'

import Checkout from './features/Checkout'
import Product from './features/Product'
import Related from './features/Related/Related'
import Scanner from './features/Scanner'
import NavBar from './layout/NavBar'
import TopBar from './layout/TopBar'
import useLayout from './layout/useLayout'
import { useTheme } from './lib/hooks'
import Style from './style'

const AppWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const AppHeader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: calc(var(--size-top-bar) + var(--size-nav-bar));
  z-index: 100;
  background-color: var(--color-primary);
  color: var(--color-inverted);
`

const AppContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  bottom: 0;
`

const App = () => {
  const location = useLocation()
  const { theme } = useLayout()
  const themes = useTheme()


  useEffect(() => {
    document.title = themes.title || 'Self Checkout'
  }, [themes])

  console.log(themes);


  return (
    <ThemeProvider theme={themes}>
      <I18nProvider>
        <Style />
        <AppWrapper>
          <AppHeader theme={theme}>
            <TopBar />
            <NavBar />
          </AppHeader>
          <AppContent>
            <AnimatePresence mode="wait">
              <Routes location={location}>
                <Route path="/checkout" element={<Checkout />} />
                <Route path="/product/:id" element={<Product />} />
                <Route path="/related/:id" element={<Related />} />
                {/* <Route path="/" element={<Navigate to="/product/12341" />} /> */}
                <Route path="/" element={<Scanner />} />
              </Routes>
            </AnimatePresence>
          </AppContent>
        </AppWrapper>
      </I18nProvider>
    </ThemeProvider>
  )
}

export default App
