import { normalize } from 'polished'
import { createGlobalStyle } from 'styled-components'

import media from './lib/media'
import { Reset } from './lib/mixins'

const Style = createGlobalStyle`
  ${normalize()}

  :root {
    --font-primary: 'Poppins', sans-serif;

    --color-body: #EAF4F1;
    --color-global: #1D1D1D;
    --color-inverted: #ffffff;
    --color-light: #ffffff;
    --color-muted: rgba(12,62,47,0.55);
    --color-muted-inverted: rgba(255,255,255,0.5);
    --color-green: #0AB500;
    --color-dark: #000329;
    --color-primary:${({ theme }) => theme.colors.global};
    --color-primary-dark:${({ theme }) => theme.colors.primaryDark};
    --color-secondary:${({ theme }) => theme.colors.primary};
    --color-line: #DCE2EC;
    --color-line-inverted: rgba(255,255,255,0.15);
    --color-red: #C91900;

    --size-top-bar: 32px;
    --size-nav-bar: 80px;
    --size-steps-bar: 50px;
  }

  html {
	  font-size: 18px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1.35;
    font-family: ${({ theme }) => theme.font};
    color: ${({ theme }) => theme.colors.primary};
    font-weight: 300;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
  }

  body {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    background-color: var(--color-body);
  }

  * {
    box-sizing: border-box;
  }


  ::-webkit-scrollbar {
    display: none;
  }

  h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: 1.25rem;
    font-weight: 600;
    line-height: 1.25;
  }

  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.35rem;
  }

  h3 {
    font-size: 1.6rem;
    margin-bottom: 1.5em;


    ${media('<=md')} {
      font-size: 1.2rem;
    }
  }

  h4 {
    font-size: 0.75rem;
    font-weight: 600;
  }

  h5 {
    font-size: 0.65rem;
    font-weight: 600;
  }

  h6 {
    font-size: 0.75rem;
    opacity: 0.5;
    font-weight: 300;
  }

  a {
    ${Reset('link')}
    color: ${({ theme }) => theme.colors.primary};
  }

  p {
    margin-top: 0;
    margin-bottom: 2rem;
    line-height: 1.5;

    strong {
      font-weight: 600;
    }
  }

  img {
    max-width: 100%;
  }

dl {
  margin: 0;
}

dt {
  color: var(--color-muted);
  display: inline-block;
  margin-right: 0.15em;
  &:after {
    content: ':';
  }
}

dd {
  display: inline-block;
  margin: 0 0 0.25em;
  font-weight: 500;
}
`

export default Style
