import { useEffect, useMemo } from 'react'
import { themes } from '../themes/clients'

export const useMount = mount => useEffect(mount, [])

export const useUnmount = unmount => useEffect(() => unmount, [])

export const useOnClickOutside = (ref, handler) => {
  useEffect(() => {
    const listener = event => {
      if (!ref.current || ref.current.contains(event.target)) {
        return
      }
      handler(event)
    }
    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener)
    return () => {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }
  }, [ref, handler])
}

export const useTheme = () => {
  return useMemo(() => {
    const clientType = process.env.REACT_APP_BUILD_TYPE.toLowerCase()
    return themes[clientType] || themes?.action
  }, [])
}

export const useModuleConfig = moduleName => {
  const theme = useTheme()
  return theme?.modules[moduleName]
}
