import { AnimatePresence } from 'framer-motion'
import { useState } from 'react'
import { useTheme } from '../../../../lib/hooks'

import FieldQty from '@/components/FieldQty'

import useCheckout from '../../hooks/useCheckout'
import { CartItemContent, CartItemImage, CartItemPrice, CartItemTools, CartItemWrapper } from './Cart.styles'
import CartItemRemoveModal from './CartItemRemoveModal'

const CartItem = ({ product }) => {
  const { id, imageUrl, subtitle, title, price, qty } = product
  const { addToCart, removeFromCart } = useCheckout()
  const [showRemoveModal, setShowRemoveModal] = useState(false)
  const themes = useTheme()

  const handeQtyChange = payload => {
    if (payload > qty) addToCart(product)
    else if (payload < qty) {
      if (payload === 0) {
        setShowRemoveModal(true)
      } else {
        removeFromCart(product)
      }
    }
  }

  return (
    <>
      <CartItemWrapper key={id}>
        <CartItemImage>
          <img src={imageUrl} alt="" />
        </CartItemImage>
        <CartItemContent>
          <h6>{subtitle}</h6>
          <h2>{title}</h2>
          <CartItemTools>
            {themes.currency === '£' ? <CartItemPrice>
              {themes.currency}
              {price.toFixed(2)}
            </CartItemPrice> : <CartItemPrice>
              {price.toFixed(2)}
              {themes.currency}
            </CartItemPrice>}

            <FieldQty value={qty} onChange={handeQtyChange} />
          </CartItemTools>
        </CartItemContent>
      </CartItemWrapper>
      <AnimatePresence mode="wait">
        {showRemoveModal && <CartItemRemoveModal product={product} onClose={() => setShowRemoveModal(false)} />}
      </AnimatePresence>
    </>
  )
}

export default CartItem
