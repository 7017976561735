import styled, { css, keyframes } from 'styled-components'

import { ButtonWrapper } from '@/components/Button/Button.styles'
import { Cover, TransitionPrimary } from '@/lib/mixins'

export const ViewWrapper = styled.div`
  ${Cover()}
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: ${props => (props.theme === 'DARK' ? 'var(--color-primary)' : 'var(--color-body)')};
  color: ${props => (props.theme === 'DARK' ? 'var(--color-inverted)' : 'var(--color-global)')};

  ${props =>
    props.hasCollapse &&
    css`
      padding-top: calc(var(--size-top-bar) + var(--size-nav-bar) + var(--size-steps-bar));
    `};
`

export const ViewScroller = styled.div`
  flex: 1 1 auto;
  overflow: auto;
  position: relative;
`

export const ViewBodyOverlay = styled.div`
  ${Cover()}
  background-color: rgba(0,0,0,0.5);
  z-index: 3;
  ${TransitionPrimary('opacity, visibility')}
  opacity: 0;
  visibility: hidden;

  ${props =>
    props.show &&
    css`
      opacity: 1;
      visibility: visible;
    `};
`

export const ViewBody = styled.div`
  min-height: 100%;
  display: flex;
  justify-content: center;
  padding: 1rem;

  ${props =>
    !props.hasCollapse &&
    css`
      padding-top: calc(var(--size-top-bar) + var(--size-nav-bar) + 1rem);

      ${props =>
        props.hasSteps &&
        css`
          padding-top: calc(var(--size-top-bar) + var(--size-nav-bar) + var(--size-steps-bar) + 1rem);
        `};
    `};

  ${props =>
    props.hasFooter &&
    css`
      padding-bottom: 4.5rem;
    `};

  ${props =>
    props.middle &&
    css`
      align-items: center;
    `};

  ${props =>
    props.cover &&
    css`
      height: 100%;
      overflow: hidden;
    `};
`

export const ViewSteps = styled.div`
  position: absolute;
  top: calc(var(--size-top-bar) + var(--size-nav-bar));
  left: 0;
  right: 0;
  display: flex;
  height: var(--size-steps-bar);
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
  font-weight: 500;
  flex-shrink: 0;
  z-index: 10;
  background-color: var(--color-light);
`

export const ViewStepsItem = styled.div`
  position: relative;
  flex: 1 0 0;
  text-align: center;
  height: var(--size-steps-bar);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  color: var(--color-muted);
  padding-left: 0.5rem;

  &:not(:last-child) {
    &:after {
      position: absolute;
      left: 100%;
      display: inline-block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: calc(var(--size-steps-bar) / 2) 0 calc(var(--size-steps-bar) / 2) 12px;
      border-color: transparent transparent transparent var(--color-light);
      content: ' ';
    }
  }

  ${props =>
    props.active &&
    css`
      color: ${({ theme }) => theme.colors.global};
      background-color: ${({ theme }) => theme.colors.primary};

      &:not(:last-child):after {
        border-color: transparent transparent transparent ${({ theme }) => theme.colors.primary};
      }
    `};

  ${props =>
    props.done &&
    css`
      color: var(--color-inverted);
      background-color: ${({ theme }) => theme.colors.primaryDark};
      z-index: 2;

      &:not(:last-child):after {
        border-color: transparent transparent transparent ${({ theme }) => theme.colors.primaryDark};
      }
    `};
`
export const ViewCollapse = styled.div`
  position: relative;
  flex: 0 0 3rem;
  z-index: 4;
`

export const ViewCollapseTitle = styled.h3`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3rem;
  text-align: center;
  margin-bottom: 0;
  z-index: 3;
  font-size: 1rem;
  font-weight: 600;
  pointer-events: none;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0));
`
const dragAnimation = keyframes`
  0%{ opacity: 0.1;}
  50%{ opacity: 0.35;}
  100%{ opacity: 0.1;}
`
export const ViewCollapseDrag = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 3rem;
  z-index: 15;
  touch-action: none;

  &:after {
    position: absolute;
    left: calc(50% - 1.5rem);
    height: 2px;
    bottom: 4px;
    width: 3rem;
    content: ' ';
    background-color: var(--color-global);
    animation: ${dragAnimation} infinite 2s linear;
  }
`

export const ViewCollapseBody = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 65vh;
  z-index: 2;
  border-bottom: 1px solid var(--color-line);
  min-height: 100%;
  ${TransitionPrimary('box-shadow')}

  &:before {
    ${Cover()}
    top: -25%;
    background-color: var(--color-light);
  }

  ${props =>
    !props.collapsed &&
    css`
      /* box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1); */
    `};
`

export const ViewCollapseBodyInner = styled.div`
  max-height: 100%;
  overflow: auto;
  padding: 3rem 1.25rem 3rem;
  ${TransitionPrimary('opacity, visibility')}

  ${props =>
    props.collapsed &&
    css`
      opacity: 0;
      visibility: hidden;
    `};
`

export const ViewCollapseInner = styled.div`
  ${Cover()}
`

export const ViewFooter = styled.footer`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  height: 4.5rem;

  ${ButtonWrapper} {
    width: 100%;
    height: 100%;
  }
`
