export const baseTheme = {
  colors: {
    body: '#ffffff',
    global: '#001489',
    primary: '#EB7514',
    inverted: '#ffffff',
    light: '#EEEEEE',
    muted: '#88929E',
    mutedInverted: 'rgba(255,255,255,0.5)',
    green: '#0AB500',
    light: '#EFF3F8',
    dark: '#000329',
    line: '#DCE2EC',
    lineInverted: 'rgba(255,255,255,0.15)',
    red: '#C91900'
  },
  fonts: {
    primary: "'Ubuntu', sans-serif"
  },
  sizes: {
    topBar: '4rem',
    gridGap: '3rem',
    viewPaddingVertical: '4rem',
    viewPaddingHorizontal: '5rem'
  }
}
